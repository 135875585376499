
/*
    Stop chrome autofill from inserting it's own colors
    https://stackoverflow.com/a/13859852/646998
*/

input:-webkit-autofill {
    box-shadow: 0 0 0 50px white inset;
    -webkit-box-shadow:0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

textarea:-internal-autofill-selected {
    box-shadow: 0 0 0 50px white inset;
    -webkit-box-shadow:0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
    box-shadow: white,0 0 0 50px white inset;
    -webkit-box-shadow: white,0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}

textarea:-internal-autofill-selected:focus {
    box-shadow: 0 0 0 50px white inset;
    -webkit-box-shadow:0 0 0 50px white inset;
    -webkit-text-fill-color: #333;
}